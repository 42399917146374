module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TCCHCGR","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"df599IOLJLtLuFFbLLcRhA","gtmPreview":"env-8","dataLayerName":"dataLayer"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
